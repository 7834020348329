.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-gray);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper.title {
  text-align: center;
}

.btn {
  margin-top: auto;
}

.selectedItem {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 30vh;
}
