.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--heading-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: var(--text-color);
  border-radius: 0 var(--block-border-radius) 0 0;
}

.sidebarContent {
  position: fixed;
  width: inherit;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.navigation {
  overflow-x: hidden;
  overflow-y: auto;
}

.head,
.footer {
  letter-spacing: 1px;
  padding: 15px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  display: block;
  text-decoration: none;
  background: linear-gradient(265.43deg, #8688f6 0%, #6c6fef 53.7%);
  border-radius: 0 var(--block-border-radius) 0 0;
  color: #fff;
}

.footer {
    margin-top: auto;
    padding: 20px 30px;
    border-radius: 0;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.head span {
  font-size: 12px;
  font-weight: 400;
}

.links {
  margin: 50px 30px;
}

.links .item {
  margin-top: 15px;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.links .item .title {
  display: block;
}

.links .item.level0 {
  margin-top: 40px;
}

.links .item.level0 > .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.links .item .label {
  font-size: 12px;
}

.links a {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  text-decoration: none;
  width: 100%;
  color: var(--text-color);
  box-sizing: border-box;
  border-radius: 14px;
}

.links a:hover,
.links a:focus {
  color: var(--text-color);
  background: var(--color-gray-4);
}

.links a.active,
.links .item.active a {
  color: var(--text-color);
  background: var(--color-gray-2);
}

:global .sidebarItem a.active {
  color: var(--text-color);
  background: var(--color-gray-2);
}

@media (max-width: 767px) {
  .sidebar {
    width: 200px;
    min-width: 200px;
  }

  .links {
    margin: 30px 15px;
  }

  .head {
    padding: 10px 15px;
  }
}
