@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --primary-color: #6c6fef;
  --text-color: #1c1b4f;

  --heading-color: var(--color-gray-3);
  --primary-color-light: #8184f8;
  --primary-color-dark: #5a5ded;
  --highlight-color: #d13267;
  --bg-color: #f8fafb;
  --block-border-radius: 16px;

  --color-gray: #878aa1;
  --color-gray-2: #e3e5f3;
  --color-gray-3: #f2f2fc;
  --color-gray-4: #f8fafb;

  --header-size: 65px;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

* {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
}
