.App {
  display: flex;
}
.App .page-container {
  flex-grow: 1;
  padding: var(--header-size) 60px 60px;
}
.App .page-block {
    background: #FFFFFF;
    box-shadow: 8px 8px 24px rgba(109, 112, 240, 0.1);
    border-radius: 16px;
    padding: 60px;
}

.App .page-container > .page-block:not(:first-of-type) {
    margin-top: 30px;
}

.page-title {
    font-size: 1em;
    color: var(--text-color);
    display: inline-block;
}

.btn {
    width: max-content;
    display: inline-flex;
    padding: 8px 30px;
    color: #fff;
    cursor: pointer;
    border: 1px solid var(--primary-color);
    text-decoration: none;
    background: var(--primary-color);
    border-radius: 3px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.btn:hover {
    color: #fff;
    background-color: var(--primary-color-light);
}

.btn:active {
    color: #fff;
    background-color: var(--primary-color-dark);
}

/* forms */
label {
    display: block;
    margin: 24px auto;
}
label span {
    display: block;
    margin-bottom: 6px;
}
input, textarea {
    padding: 8px 10px;
    font-size: 1em;
    color: #777;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #E3E5F3;
    border-radius: 3px;
}
input::placeholder {
    color: var(--color-gray);
}
textarea {
    min-height: 160px;
}
.error {
    color: red;
    background: pink;
    border: 1px solid red;
    border-radius: 4px;
    padding: 8px;
    margin: 10px 0;
}

@media (max-width: 767px) {
    .App .page-container {
        padding: var(--header-size) 20px 20px;
    }

    .App .page-block {
        padding: 20px;
    }
}
